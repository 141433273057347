import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import BlankLayout from "@/views/Layout/BlankLayout.vue";
import Middleware from "@/middleware";
const Page = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/Show.vue");
const New = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/New.vue");
// const Edit = () => import("@/modules/assign_camp/views/TemporaryAllocationOrder/Edit.vue");
export const TemporaryAllocationOrder = {
    path: "/",
    component: DashboardLayout,
    name: "TemporaryAllocationOrder",
    children: [
        {
            path: "/temp-allocation-orders/create",
            name: "NewTemporaryAllocationOrder",
            component: New,
            meta: {
                groupName: "Assign",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "assign-create",
            },
        },
        // {
        //     path: "/assign/update/:id",
        //     name: "EditTemporaryAllocationOrder",
        //     component: Edit,
        //     meta: {
        //         groupName: "TemporaryAllocationOrder",
        //         middlewares: [
        //             Middleware.auth,
        //             Middleware.permission,
        //             Middleware.signeture,
        //         ],
        //         permissions: "assign-update",
        //     },
        // },

        {
            path: "/temp-allocation-orders",
            name: "TemporaryAllocationOrderPage",
            component: Page,
            meta: {
                groupName: "TemporaryAllocationOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-assign-index",
            },
        },
    ],
};

