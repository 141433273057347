
export const tamplates =
{

    action: "mdi-clipboard-account-outline",
    title: "Tamplates",
    permission: "tamplate-form",
    items: [
        {
            title: "Question View",
            prefix: "K",
            link: "/questions",
            permission: "tamplate-question-index",
        },
        {
            title: "QuestionCategoryView",
            prefix: "K",
            link: "/question-category",
            permission: "tamplate-question_category-index",
        },
        {
            title: "Tamplate Form View",
            prefix: "K",
            link: "/forms",
            permission: "tamplate-form-index",
        },

    ],
};
export const assign_camp = {
    action: "mdi-tent",
    title: "assign camp",
    permission: "delivery",
    items: [
        {
            title: "ShowSquare",
            prefix: "IS",
            link: "/squares",
            permission: "delivery-square-index",
        },
        {
            title: "ShowCamp",
            prefix: "SC",
            link: "/camps",
            permission: "delivery-camp-index",
        },
        {
            title: "assign View",
            prefix: "K",
            link: "/assign",
            permission: "delivery-assign-index",
        },
        {
            title: "NewAppointment",
            prefix: "I C",
            link: "/create-appointments",
            permission: "delivery-appointment-creat-index",
        },
        {
            title: "Appointments view",
            prefix: "I C",
            link: "/appointments",
            permission: "delivery-appointment-index",
        },
        {
            title: "Absence history",
            prefix: "SI",
            link: "/absence-history",
            permission: "absence-history",
        },
        {
            title: "Stations",
            prefix: "SP",
            link: "/stations",
            permission: "delivery-station-index",
        },
        {
            title: "Countries",
            prefix: "SI",
            link: "/countries",
            permission: "delivery-countries-index",
        },
        {
            title: "TemporaryAllocationOrderPage",
            prefix: "IS",
            link: "/temp-allocation-orders",
            permission: "delivery-appointment-index",
        },
    ],
};

